import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';

const About = center => (
  <Layout>
    <Helmet title={'About Payment Services Directive - PSD2'} />
    <Header title="Payment Services Directive">About PSD2.ie</Header>
    <Container center={center}>
      <h2>What we provide</h2>
      <p>
        This blog provides commentary on the latest news about PSD2, and also
        articles explaining PSD2 to both consumers and implementors.
      </p>

      <h2>Why?</h2>
      <p>
        This is an area of interest as I have worked in the peripheries of the
        banking industry, and worked on a fintech startup - since defunct -
        focussing on these technologies.
      </p>
    </Container>
  </Layout>
);

export default About;

About.propTypes = {
  center: PropTypes.object,
};
